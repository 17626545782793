@import '/styles/base.scss';

.root {
  > div {
    display: inline-flex;
    flex-direction: column;

    &:nth-child(2) {
      order: 1;

      @include md {
        order: initial;
      }
    }

    &:nth-child(1) {
      order: 2;

      @include md {
        order: initial;
      }
    }
  }

  .content {
    @include md {
      padding-right: 2.4rem;
    }

    @include lg {
      padding-right: 0;
    }
  }
}

.link {
  position: relative;
  color: $color-black;
  outline: none;
  padding-block: 3.2rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba($color-black, 0.02);
  }
}

.focus {
  .link:focus-visible & {
    &::before {
      position: absolute;
      top: -1rem;
      right: 1%;
      bottom: -1rem;
      left: 1%;
      border: 2px solid $color-black;
      content: '';
    }
  }
}

.externalLink {
  margin-top: 1.2rem;
}

.logoWrapper {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  padding: 1.2rem;
  margin-bottom: 2.4rem;
  background: var(--theme-primary);

  @include md {
    margin-bottom: 0;
  }

  > div {
    flex: 1;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      aspect-ratio: 1.5;
    }
  }

  @include md-only {
    padding: 0.8rem;
  }

  svg {
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
  }

  svg,
  path,
  rect {
    fill: var(--theme-copy);
  }
}

.image {
  height: auto;
  margin-bottom: 2rem;

  @include md {
    margin-bottom: 0;
  }
}

.imageWithShading {
  overflow: hidden;

  &::after {
    position: absolute;
    display: block;
    background-color: var(--theme-primary);
    content: '';
    inset: 0;
    opacity: 0.7;
    transition: opacity 1s ease;
  }
}

.link:hover {
  .imageWithShading::after {
    opacity: 0;
  }

  .videoThumbnail {
    opacity: 0;
    transform: none !important;
  }
}

.title {
  word-break: break-word;
}

.description {
  margin-top: 1.2rem;
}

.caption {
  margin-top: 1.2rem;

  & + & {
    margin-top: 0.4rem;
  }

  > span {
    margin-right: 0.4rem;
    color: rgba($color-black, 0.6);
  }
}

.videoThumbnail {
  position: absolute;
  z-index: 1;
  right: 0.4rem;
  bottom: 0.4rem;
  width: 3.2rem;
  color: var(--theme-copy);
  transition: opacity 1s ease !important;
}

.skeleton {
  > div {
    height: 1.3rem;
    animation: loading 1.7s ease infinite;
    background-color: rgba($color-black, 0.08);
  }
}

.skeletonContent {
  order: 3;

  > div {
    &:nth-child(1) {
      width: 60%;
      height: 3.8rem;
      margin-bottom: 2.2rem;
    }

    &:nth-child(2) {
      width: 95%;
      margin-bottom: 1.7rem;
    }

    &:nth-child(3) {
      width: 95%;
      margin-bottom: 1.2rem;
    }

    &:nth-child(4) {
      width: 20%;
      margin-bottom: 1.2rem;
    }
  }

  @include md {
    order: initial;
  }
}

.skeletonImage {
  width: 100%;
  height: auto;
  order: 2;
  margin-bottom: 1.6rem;
  animation: loading 1.7s ease infinite;
  aspect-ratio: 4/5;
  background-color: rgba($color-black, 0.08);

  > div {
    height: 0;
  }

  @include md {
    order: initial;
    margin-bottom: 0;
  }
}
