@use '/styles/base' as *;

.root {
  display: flex;
  justify-content: space-between;
  padding-top: 2.4rem;

  @include md {
    padding-top: 4rem;
  }

  @include lg {
    height: var(--nav-wordmark-height);
    align-items: flex-end;
    padding-top: 0;
    padding-right: 4rem;
    margin-top: var(--nav-wordmark-spacing);
  }
}
