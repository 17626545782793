@use '/styles/base' as *;

.root {
  display: grid;
  width: 100%;

  grid-template-areas:
    'preheader preheader preheader preheader preheader'
    'spacer spacer spacer spacer spacer'
    'eyebrow eyebrow eyebrow eyebrow eyebrow'
    'text text text text text'
    'credits credits credits credits credits'
    '. quickLinks quickLinks quickLinks quickLinks'
    'image image image image image';
  grid-template-columns: repeat(5, 1fr);

  @include md {
    grid-template-areas:
      'preheader preheader preheader preheader preheader preheader preheader'
      'spacer spacer spacer spacer spacer spacer spacer'
      'eyebrow eyebrow eyebrow eyebrow eyebrow eyebrow .'
      'text text text text text quickLinks quickLinks'
      'credits credits credits credits credits quickLinks quickLinks'
      'image image image image image image image';
    grid-template-columns: repeat(7, 1fr);

    &.portraitImage {
      grid-template-areas:
        'preheader preheader preheader preheader preheader preheader preheader'
        'spacer spacer spacer spacer spacer spacer spacer'
        'eyebrow eyebrow eyebrow eyebrow eyebrow eyebrow .'
        'text text text text text quickLinks quickLinks'
        'credits credits credits image image image image';
    }
  }

  @include lg {
    grid-template-areas:
      '. preheader preheader preheader preheader preheader preheader preheader preheader'
      '. spacer spacer spacer spacer spacer spacer spacer spacer'
      '. eyebrow eyebrow eyebrow eyebrow eyebrow eyebrow . .'
      '. text text text text text text quickLinks quickLinks'
      '. credits credits credits credits credits credits quickLinks quickLinks'
      'image image image image image image image image image';
    grid-template-columns: repeat(9, 1fr);

    &.portraitImage {
      grid-template-areas:
        '. preheader preheader preheader preheader preheader preheader preheader preheader'
        '. spacer spacer spacer spacer spacer spacer spacer spacer'
        '. eyebrow eyebrow eyebrow eyebrow eyebrow eyebrow . .'
        '. text text text text text text quickLinks quickLinks'
        '. credits credits credits image image image image image';
    }
  }

  &.isLandingPage {
    margin-bottom: 8.8rem;
    @include md {
      margin-bottom: 12rem;
    }
    @include lg {
      margin-bottom: 16rem;
    }
  }
  &:not(.isLandingPage) {
    margin-bottom: 4.8rem;
    @include md {
      margin-bottom: 6.4rem;
    }
    @include lg {
      margin-bottom: 8.8rem;
    }
  }
}

.preheader {
  grid-area: preheader;
}

.spacer {
  height: 8.8rem;
  grid-area: spacer;

  @include md {
    height: 12rem;
  }

  @include lg {
    .root:has(.eyebrow) & {
      --offset: 1lh;
    }
    .root:not(:has(.eyebrow)) & {
      --offset: 1em;
    }
    // firefox doesn't support :has so we give 1em as the fallback offset
    height: calc(
      var(--nav-wordmark-spacing) + var(--primary-navigation-height) - var(--offset, 1em)
    );
  }
}

.eyebrow {
  margin-bottom: 1.6rem;
  grid-area: eyebrow;
}

.titleAndDescription {
  grid-area: text;
}

.description {
  margin-top: 1.6rem;

  @include md {
    padding-right: 4.8rem;
  }

  @include lg {
    padding-right: 6.4rem;
    margin-top: 2.4rem;
  }
}

.quickLinks {
  height: min-content;
  margin-top: 6.4rem;
  grid-area: quickLinks;

  @include md {
    margin-top: 0;
  }
}

.creditSection {
  grid-area: credits;

  .root:not(.portraitImage) & {
    margin-top: 4rem;

    @include md {
      margin-top: 3.2rem;
    }

    @include lg {
      margin-top: 4rem;
    }
  }
}

.titleAndDescription,
.creditSection {
  @include md {
    padding-right: 4.8rem;
  }
  @include lg {
    padding-right: 6.4rem;
  }
}

.image,
.creditSection {
  margin-top: 4rem;

  @include md {
    margin-top: 4.8rem;
  }

  @include lg {
    margin-top: 6.4rem;
  }
}

.image {
  display: flex;
  flex-direction: column;
  grid-area: image;

  & .caption {
    flex: 1;
  }
}

.caption {
  margin-top: 1.6rem;
  color: $color-caption;

  span {
    display: block;

    margin-inline: $margin-sm;
    @include md {
      margin-inline: $margin-md;
    }
    @include lg {
      // match right margin of rich text
      margin-inline: $margin-lg 4rem;
    }

    .portraitImage & {
      @include md {
        margin-left: 0;
      }
      @include lg {
        margin-right: $margin-md;
      }
    }
  }
}
