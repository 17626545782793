.root {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.creditLabel {
  margin-right: 0.4rem;
  opacity: 0.6;
}
