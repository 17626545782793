.pills {
  margin-bottom: 3.2rem;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
}

.icon {
  width: 1.6rem;
  margin-left: 1rem;
}
