@import '/styles/base.scss';

.bar {
  position: sticky;
  z-index: $z-100;
  top: -0.1rem;
  padding: $filter-bar-padding 0;
  border-bottom: 1px solid transparent;
  background-color: transparent;
  transition:
    border-bottom-color 0.3s ease,
    background-color 0.3s ease;
  visibility: visible;

  &.isSticky {
    visibility: hidden;
  }
}

.scrollBar {
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0;
  transition: opacity 0.3s ease;
  visibility: hidden;

  &.isSticky {
    padding: $filter-bar-padding 0;
    padding-top: $filter-bar-padding-sm;
    border-bottom: 1px solid $color-gray-light;
    background-color: $color-white;
    opacity: 1;
    visibility: visible;

    @include md {
      padding-top: $filter-bar-padding-md;
    }

    @include lg {
      padding-top: $filter-bar-padding;
    }
  }
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.action {
  &.round {
    padding: 1rem;
  }

  span + .icon {
    margin-left: 1.2rem;
  }
}

.icon {
  width: 1.6rem;
}

.title {
  opacity: 0;
  transition: opacity 0.3s ease;

  &.active {
    opacity: 1;
  }
}

.barTitle {
  width: 75%;
}
