@import '/styles/base.scss';

.root {
  display: flex;
  color: var(--theme-copy);

  &.portrait {
    flex-direction: column;

    @include md {
      flex-direction: row;
    }
  }

  &.landscape {
    flex-direction: column;
  }
}

.image {
  overflow: hidden;
  width: 100%;
  height: auto;

  .portrait & {
    height: 100%;

    @include md {
      max-width: 65%;
    }
  }
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  padding: 2.4rem 2.4rem 4rem;
  background-color: var(--theme-primary);

  @include md {
    padding: 4rem 4rem 4.8rem;
  }
}

.eyebrow {
  margin-bottom: 0.8rem;
}

.title {
  margin-bottom: 2.4rem;

  @include md {
    margin-bottom: 3.2rem;
  }
}

.actionWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  .portrait & {
    @include md {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.linkEvents {
  justify-content: center;
  margin-left: 2.6rem;

  .portrait & {
    @include md {
      margin-top: 2.6rem;
      margin-left: 0;
    }
  }
}
